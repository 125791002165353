import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { Container } from "reactstrap"

let About = () => {
  return (
    <>
      <SEO
        title="BIM & CAD Training Center | Ace Industrial Academy"
        description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
      />
      <PageTitle title="BIM & CAD Training Center" />
      <Container className="pt-4">
        <p>
          Ace Industrial Academy is the leading training and
          certification center for BIM in Singapore. Since
          2007, we have worked with consultants,
          contractors, and government agencies in the
          built-environment sector to provide skills
          training in BIM.
        </p>
        <p>
          We develop training programmes based on SSG
          guidelines that ensure that learners obtain the
          necessary skills to perform on their jobs. Find
          out more about out{" "}
          <Link to="/courses">full list of courses</Link>.
        </p>
      </Container>
    </>
  )
}

export default About
